import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Dufflebags() {
  return (
    <Layout>
      <GetItems search="Duffle Bag" title="Duffle Bags" />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./accessories/dufflebags'} title="STM Quality Duffle Bags: Positivity and Awareness" description={"Shopping for duffle bags to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)